import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

const RoomHubSignalRContext = createContext();

export const RoomHubSignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const [roomCode, setRoomCode] = useState(null);
  const [scoreboard, setScoreboard] = useState(null);
  const [profile, setProfile] = useState(null);
  const [quizInfo, setQuizInfo] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentAnswers, setCurrentAnswers] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const currentQuestionRef = useRef();
  currentQuestionRef.current = currentQuestion;

  useEffect(() => {
    if (connection == null) return;

    connection.on('ShowQuestion', (question) => {
      setCurrentQuestion(question);
      setCurrentAnswers([]);
    });

    connection.on('Answered', (answerIds) => {
      setCurrentAnswers(answerIds);
    });

    connection.on('ClearQuestion', (questionId) => {
      if (currentQuestionRef.current != null && currentQuestionRef.current.id == questionId)
        setCurrentQuestion(null);
    });

    connection.on('SetProfile', (name) => {
      setProfile(name);
    });

    connection.on('QuizInfo', (quizInfo) => {
      setQuizInfo(quizInfo);
    });

    connection.on('UserId', (userId) => {
      setUserId(userId);
    });

    connection.on('GetScoreboard', (scoreboard) => {
      setScoreboard(scoreboard);
    });

    connection.onclose(() => setConnectionStatus("disconnected"));
    connection.onreconnecting(() => setConnectionStatus("reconnecting"));
    connection.onreconnected(() => setConnectionStatus("connected"));

    return () => {
      connection.stop().catch((err) => console.error('SignalR stop error:', err));
    };
  }, [connection]);

  function connect(code) {
    setRoomCode(code);
    setConnectionStatus("connecting");
    const newConnection = new HubConnectionBuilder()
      .withUrl(`/hubs/quiz?code=${code}`)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    
    newConnection.start()
      .then(() => {
        setConnectionStatus("connected");
        console.log('SignalR connected');
      })
      .catch((err) => {
        setConnectionStatus("error")
        console.error('SignalR connection error:', err);
      });
  };

  const answerQuestion = (answer) => {
    connection.invoke('AnswerQuestion', answer)
      .catch((err) => console.error('AnswerQuestion error:', err));
  }

  const updateProfile = (newName) => {
    connection.invoke('ChangeName', newName)
      .catch((err) => console.error('ChangeName error:', err));
  }

  return (
    <RoomHubSignalRContext.Provider value={{ connect, connectionStatus, currentQuestion, answerQuestion, scoreboard, profile, updateProfile, quizInfo, userId, currentAnswers }}>
      {children}
    </RoomHubSignalRContext.Provider>
  );
};

export const useRoomHubSignalR = () => {
  return useContext(RoomHubSignalRContext);
};