import { Index } from "./pages/Index";
import { Room } from "./pages/Room";
import { Quizzes } from "./pages/Quizzes";
import { Quiz } from "./pages/Quiz";
import { Host } from "./pages/Host";

const AppRoutes = [
  {
    index: true,
    element: <Index />
  },
  {
    path: '/quizzes',
    element: <Quizzes />
  },
  {
    path: '/quizzes/:quizId',
    element: <Quiz />
  },
  {
    path: '/room/:code',
    element: <Room />
  },
  {
    path: '/host/:quizId',
    element: <Host />
  },
];

export default AppRoutes;
