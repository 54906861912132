import React from 'react';
import { Badge, Spinner } from 'reactstrap';

export function ConnectionStatusBadge(props) {
  const mappings = {
    "connecting": {
      "color": "success",
      "spinner": true
    },
    "connected": {
      "color": "success",
    },
    "reconnecting": {
      "color": "warning",
      "spinner": true
    },
    "error": {
      "color": "danger",
    },
    "disconnected": {
      "color": "danger",
    }
  };
  function mapColor(status){
    if (status in mappings)
      return mappings[status].color;
    return "secondary";
  }
  function showSpinner(status){
    if (status in mappings && "spinner" in mappings[status])
      return mappings[status].spinner;
    return false;
  }

  const color = mapColor(props.connectionStatus);

  return (
    <Badge pill={true} color={`${color}-subtle`} className={`text-capitalize text-${color}-emphasis border border-${color}-subtle text-center d-flex align-items-center`}>
      {showSpinner(props.connectionStatus) && <Spinner size={"sm"} type={"grow"} className={"me-1"} />}
      {props.connectionStatus}
    </Badge>
  );
}