import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Scoreboard } from '../components/scoreboard';
import { ConnectionStatusBadge } from '../components/connectionStatusBadge';
import { Timer } from '../components/timer';
import { Button, Col, Row } from 'reactstrap';
import { useRoomHubSignalR } from '../providers/RoomHubSignalRProvider';

export function Room() {
  const { code } = useParams();
  const { connect, connectionStatus, currentQuestion, answerQuestion, scoreboard, profile, updateProfile, quizInfo, userId, currentAnswers } = useRoomHubSignalR();
  const [answered, setAnswered] = useState(false);
  const [hasHadQuestion, setHasHadQuestion] = useState(false)

  useEffect(() => {
    if (!code) return;

    return connect(code);
  }, [code]);

  useEffect(() => {
    if (currentQuestion != null) {
      setAnswered(false);

      setHasHadQuestion(true);
    }
  }, [currentQuestion])

  async function answerQuestionForm(event) {
    event.preventDefault();

    var answer = event.nativeEvent.submitter.value;

    setAnswered(answer);
    answerQuestion(answer);
  }

  async function updateName(event) {
    event.preventDefault();

    var form = new FormData(event.target);
    var newName = form.get("name");
    event.target.name.value = "";

    updateProfile(newName);
  }

  return (
    <div>
      {quizInfo && <header class="py-3 mb-4 border-bottom">
        <div class="container d-flex flex-wrap justify-content-center">
          <div className="mb-lg-0 me-auto">
            <h2>
              {quizInfo.name}
            </h2>
          </div>
          <div className="d-flex align-items-center ">
            <ConnectionStatusBadge connectionStatus={connectionStatus} />
          </div>
        </div>
      </header>}

      <Row>
        <Col md={7}>
          {!hasHadQuestion && <div>
            <h2>Waiting for host</h2>
            <p>We're just waiting for your host to send a question...<br />Please wait...</p>
          </div>}
          {hasHadQuestion && !currentQuestion && <div>
            <h2>Waiting for next question</h2>
            <p>Fingers on buzzers, please!</p>
          </div>}
          {currentQuestion &&
            <form onSubmit={answerQuestionForm}>
              <h2>{currentQuestion.question}</h2>

              {!!currentQuestion.seconds && <Timer className={"my-2"} seconds={currentQuestion.seconds} />}

              <div class="d-flex align-items-stretch row g-3">
                {currentQuestion.options.map(x => (
                  <div key={x.id} class="col-6">
                    <Button
                      type="submit"
                      name="selection"
                      value={x.id}
                      disabled={answered}
                      color={!answered || (currentAnswers == null || currentAnswers.length == 0) ? "primary" : (currentAnswers.includes(x.id) ? "success" : "danger")}
                      outline={answered !== x.id}
                      size="lg"
                      className="w-100 h-100">
                      {!x.text.startsWith("img: ") && x.text}
                      {x.text.startsWith("img: ") && <img className="border border-4 w-100" src={x.text.substring("img: ".length)} />}
                    </Button>
                  </div>
                ))}
              </div>
            </form>
          }
        </Col>
        <Col md={5}>
          <form onSubmit={updateName}>
            <div className="mb-3">
              <label class="form-label">Display name</label>
              <div class="input-group">
                <input type="text" class="form-control" name="name" autoComplete={"off"} placeholder={profile} />
                <button class="btn btn-outline-primary" type="submit">Update</button>
              </div>
            </div>
          </form>
          <Scoreboard currentUserId={userId} scoreboard={scoreboard} />
        </Col>
      </Row>
    </div>
  );
}