import React, { useState, useEffect } from 'react';
import { Progress } from 'reactstrap';

export function Timer(props) {
  const [start, _] = useState(Date.now());
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSeconds((Date.now() - start) / 1000);
    }, 200);

    return () => clearInterval(timerInterval);
  }, [start]);

  return (
    <Progress className={props.className} min={0} max={props.seconds} value={Math.max(0, props.seconds - seconds)}>
      {Math.max(0, Math.round((props.seconds - seconds) * 10) / 10)}s
    </Progress>
  );
}