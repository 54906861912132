import { faCheck, faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Progress, Table } from 'reactstrap';
import { ConfirmationButton } from './confirmationButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'react-oidc-context';

export function QuizQuestion(props) {
  const auth = useAuth();
  const [showAddOptionSection, setShowAddOptionSection] = useState(false);

  async function reorderQuestion(questionId, direction) {
    const token = auth.user?.access_token;
    var response = await fetch(
      `api/quiz/${props.quizId}/questions/${questionId}/reorder?direction=${direction}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    await response.text();

    await props.reloadQuiz();
  }

  async function deleteQuestion(questionId) {
    const token = auth.user?.access_token;

    var response = await fetch(
      `api/quiz/${props.quizId}/questions/${questionId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    await response.text();

    return await props.reloadQuiz();
  }

  async function deleteQuestionOption(questionId, optionId) {
    const token = auth.user?.access_token;

    var response = await fetch(
      `api/quiz/${props.quizId}/questions/${questionId}/options/${optionId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    await response.text();

    return await props.reloadQuiz();
  }

  async function addQuestionOption(event) {
    event.preventDefault();

    const token = auth.user?.access_token;

    var formData = new FormData(event.target);
    var questionId = formData.get("questionId");
    var isAnswer = event.nativeEvent.submitter.value;
    var data = {
      "isAnswer": isAnswer === "true",
      "questionId": questionId,
      "text": formData.get("text")
    };
    event.target["text"].value = "";

    var response = await fetch(
      `api/quiz/${props.quizId}/questions/${questionId}/options`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': "application/json"
        },
        body: JSON.stringify(data)
      });
    await response.json();

    await props.reloadQuiz();
  }

  return (
    <Card className={"m-4"}>
      <CardHeader className={"d-flex align-items-center"}>
        <h3 className={"flex-fill"}>{props.title}</h3>

        <ButtonGroup size={"sm"} className={"me-3"}>
          {props.showReorderUp &&
            <Button color={"primary"} outline={true} onClick={() => reorderQuestion(props.question.id, -1)}>
              <FontAwesomeIcon className={"rounded-circle flex-shrink-0"} icon={faChevronUp} />
            </Button>}
          {props.showReorderDown &&
            <Button color={"primary"} outline={true} onClick={() => reorderQuestion(props.question.id, 1)}>
              <FontAwesomeIcon className={"rounded-circle flex-shrink-0"} icon={faChevronDown} />
            </Button>}
        </ButtonGroup>

        <ConfirmationButton size="sm" outline={true} onConfirm={() => deleteQuestion(props.question.id)} color="danger"
          message={`This is a permanent action. By confirming, you will be deleting the question "${props.question.question}" and the options it has.`}>
          Delete
        </ConfirmationButton>
      </CardHeader>

      <CardBody>
        {props.question.options.length === 0 && <p>No options added</p>}
        {props.question.options.length > 0 &&
          <div>
            <Table>
              <thead>
                <tr>
                  <th className={`w-100`}>Option</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {props.question.options.map(option => {
                  return (
                    <tr key={option.id} className={`${option.isAnswer ? "table-success" : undefined}`}>
                      <td>
                        <div className="d-flex align-items-start">
                          {option.text.startsWith("img: ") && <img className="me-3" style={{ "height": "128px" }} src={option.text.substring("img: ".length)} />}
                          {option.text}
                          {option.isAnswer && <span class="ms-2 badge text-bg-secondary">Answer</span>}<br />
                        </div>
                      </td>
                      <td>
                        <ConfirmationButton size="sm" outline={true} onConfirm={() => deleteQuestionOption(props.question.id, option.id)} color="danger"
                          message={`This is a permanent action. By confirming, you will be deleting the option "${option.text}".`}>
                          Delete
                        </ConfirmationButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        }

        {showAddOptionSection && <div>
          <hr />
          <h5>Add new option</h5>
          <form onSubmit={addQuestionOption}>
            <input type="hidden" name="questionId" value={props.question.id} />

            <div className="mb-2">
              <label for="option-text" className="form-label">Option text</label>
              <input type="text" className="form-control" id="option-text" name="text" autoComplete={"off"}
                placeholder="Paris" />
            </div>
            <button type="submit" name="isAnswer" value="false" className="btn btn-primary btn-sm mt-2 me-2">
              <FontAwesomeIcon className={"rounded-circle flex-shrink-0 me-1"} icon={faXmark} />
              Add as incorrect</button>
            <button type="submit" name="isAnswer" value="true" className="btn btn-primary btn-sm mt-2 me-2">
              <FontAwesomeIcon className={"rounded-circle flex-shrink-0 me-1"} icon={faCheck} />
              Add as answer
            </button>
            <button className="btn btn-secondary btn-sm mt-2" onClick={() => setShowAddOptionSection(false)}>
              Cancel
            </button>
          </form>
        </div>}
        {!showAddOptionSection && <Button size="sm" outline={false} color={"primary"} onClick={() => setShowAddOptionSection(true)}>Add question</Button>}
      </CardBody>
    </Card>
  );
}