import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faUsers, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { Button, ListGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export function Index() {
  const navigate = useNavigate();
  const [helpModal, setHelpModal] = useState(false);

  const toggleHelpModal = () => setHelpModal(!helpModal);

  function joinByCode(event) {
    event.preventDefault();

    var formData = new FormData(event.target);
    var code = formData.get("code");

    navigate(`/room/${code}`);
  }

  function goToQuizzes(event) {
    event.preventDefault();

    navigate(`/quizzes`);
  }

  return (
    <div>
      <div className="d-flex flex-column p-4 gap-4 py-md-5 align-items-center justify-content-center">
        <div>
          <div className="list-group">
            <div className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
              <FontAwesomeIcon className={"fa-2xl rounded-circle flex-shrink-0"} width={32} height={32} icon={faUsers} />
              <div className="d-flex gap-2 w-100 justify-content-between">

                <div>
                  <h6 className="mb-0">Join by code</h6>
                  <p className="mb-0 opacity-75">Been given a code? Put it here!</p>

                  <form id="join-by-code" onSubmit={joinByCode}>
                    <div className="input-group mt-3">
                      <input type="text" name="code" className="form-control outline-primary"
                        placeholder="Quiz code" aria-label="Quiz code" />
                      <button className="btn btn-outline-primary" type="submit">Join</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <button onClick={toggleHelpModal} data-bs-toggle="modal" data-bs-target="#exampleModal"
              className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
              <FontAwesomeIcon className={"fa-2xl rounded-circle flex-shrink-0"} width={32} height={32} icon={faGraduationCap} />
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Learn</h6>
                  <p className="mb-0 opacity-75">A whistle-stop tour on how to play!</p>
                </div>
                <small className="opacity-50 text-nowrap">5 minutes</small>
              </div>
            </button>
          </div>

          <ListGroup className={"mt-4"}>

            <div className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
              <FontAwesomeIcon className={"fa-2xl rounded-circle flex-shrink-0"} width={32} height={32} icon={faWandMagicSparkles} />
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Be a host</h6>
                  <p className="mb-0 opacity-75">Manage and host your own quizzes</p>

                  <form id="create-room" onSubmit={goToQuizzes}>
                    <div className="input-group mt-3">
                      <button className="btn btn-outline-secondary btn-sm" type="submit">Let's go</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </ListGroup>
        </div>
      </div>

      <Modal isOpen={helpModal} toggle={toggleHelpModal}>
        <ModalHeader toggle={toggleHelpModal}>How to play</ModalHeader>
        <ModalBody>
          I will soon add something here 🥳
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleHelpModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>

  );
}
