import React from 'react';
import { Table } from 'reactstrap';

export function Scoreboard(props) {
  return (
    <div>
        {props.scoreboard &&
          <div>
            <Table>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {props.scoreboard.players.length == 0 && <tr>
                  <td><i>No players connected</i></td>
                  <td></td>
                </tr>}
                {props.scoreboard.players.map(x => (
                  <tr className={props.currentUserId === x.id ? "table-success" : ""} key={x.id}>
                    <td>{x.name}</td>
                    <td>{x.score}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
      }
    </div>
  );
}