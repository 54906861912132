import React, { useEffect, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Table, Card, CardHeader, CardBody } from 'reactstrap';

export function Quizzes() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [quizzes, setQuizzes] = useState(null);

  async function getQuizzes() {
    const token = auth.user?.access_token;
    var response = await fetch(
      'api/quiz',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    var result = await response.json();
    setQuizzes(result);
  }

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    getQuizzes();
  }, [auth.isAuthenticated]);

  async function deleteQuiz(e, quizId) {
    e.stopPropagation();
    const token = auth.user?.access_token;

    var response = await fetch(
      `api/quiz/${quizId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    await response.text();

    await getQuizzes();
  }

  async function createQuiz(event) {
    event.preventDefault();

    const token = auth.user?.access_token;

    var formData = new FormData(event.target);
    var name = formData.get("name");

    var response = await fetch(
      'api/quiz?' + new URLSearchParams({
        "name": name
      }),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
    await response.text();

    await getQuizzes();
  }

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <div>
        <h2>Your quizzes:</h2>

        {!quizzes && <p>Loading...</p>}
        {quizzes && quizzes.length == 0 && <p>No quizzes.</p>}
        {quizzes && quizzes.length > 0 &&
          <Table className={"table-hover"}>
            <thead>
              <tr>
                <th className={"w-100"}>Quiz name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {quizzes.map(element => {
                return (
                  <tr role="button" onClick={() => navigate(`/quizzes/${element.id}`)} key={element.id}>
                    <td>{element.name}</td>

                    <td>
                      <ButtonGroup>
                        <Button color="primary" outline={true}>View</Button>
                        <Button onClick={(e) => deleteQuiz(e, element.id)} color="danger" outline={true}>Delete</Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        }

        <Card className={"border-primary"}>
          <CardHeader>
            <h4>New quiz</h4>
          </CardHeader>
          <CardBody>
            <form onSubmit={createQuiz}>
              <div className="mb-2">
                <label for="quizName" className="form-label">Quiz Name</label>
                <input type="text" className="form-control" id="quizName" name="name"
                  placeholder="My Best Quiz!" />
              </div>

              <button type="submit" class="btn btn-outline-primary btn-sm">Add</button>
            </form>
          </CardBody>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <h2>Let's get started</h2>
      <p>You need an account to create & host a room!</p>
      <Button onClick={() => void auth.signinRedirect()}>Login</Button>
    </div>
  );


}