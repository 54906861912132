import React, { Component } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from 'oidc-client-ts';
import { HostHubSignalRProvider } from './providers/HostHubSignalRProvider';
import { RoomHubSignalRProvider } from './providers/RoomHubSignalRProvider';

const oidcConfig = {
  authority: "https://auth.pub-quiz.eu.sneezey.uk/",
  client_id: "pub-quiz",
  client_secret: "155d4881-9750-4a3d-b581-68530e82c1cc",
  redirect_uri: `${window.location.origin}/signin-oidc`,
  post_logout_redirect_uri: `${window.location.origin}/signout-oidc`,
  scope: "openid profile pubquiz-api:write",
  resource: "urn:pubquiz-api",
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export default function App()
{
  var navigate = useNavigate();

  return (
    <AuthProvider {...oidcConfig} onSigninCallback={() => navigate("/")} onSignoutCallback={() => navigate("/")}>
      <RoomHubSignalRProvider>
        <HostHubSignalRProvider>
          <Layout>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
            </Routes>
          </Layout>
        </HostHubSignalRProvider>
      </RoomHubSignalRProvider>
    </AuthProvider>
  );
}