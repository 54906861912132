import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export function ConfirmationButton(props) {
  var [modal, setModal] = useState(false);
  var [confirming, setConfirming] = useState(false);

  const toggle = () => setModal(!modal);
  const confirm = async () => {
    setConfirming(true);
    await props.onConfirm();
    toggle();
  };

  return (
    <div>
      <Button {...props} onClick={() => setModal(true)}>{props.children}</Button>

      {modal && <Modal isOpen={modal} toggle={toggle} {...props}>
        <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
        <ModalBody>
          <p>{props.message}</p>
        </ModalBody>
        <ModalFooter>
          {confirming && <div class="spinner-border spinner-border-sm me-2" role="status">
            <span class="visually-hidden">Please wait...</span>
          </div>}
          <Button disabled={confirming} color={props.color} onClick={confirm}>
            Confirm
          </Button>
          <Button disabled={confirming} color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>}
    </div>
  );
}