import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, InputGroup, InputGroupText, Row, Spinner, Table } from 'reactstrap';
import { useHostHubSignalR } from '../providers/HostHubSignalRProvider';
import { Scoreboard } from '../components/scoreboard';
import { Timer } from '../components/timer';
import { ConnectionStatusBadge } from '../components/connectionStatusBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPause, faPlay, faPlus, faStop, faSubtract } from '@fortawesome/free-solid-svg-icons';

export function Host() {
  const auth = useAuth();
  const { connect, connectionStatus, roomCode, sendQuestion, scoreboard, sending } = useHostHubSignalR();
  const { quizId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [questions, setQuestions] = useState(null);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isAutoplay, setIsAutoplay] = useState(false);
  const [autoplayTimer, setAutoplayTimer] = useState(10); // Default 10 seconds

  useEffect(() => {
    let timer;
    if (isAutoplay && currentQuestionIndex < questions.length) {
      var desiredTimer = questions[currentQuestionIndex].autoplayTimer || autoplayTimer;
      sendQuestion(quizId, questions[currentQuestionIndex].id, desiredTimer);
      timer = setTimeout(() => {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      }, (desiredTimer * 1000) + 1000);
    }

    return () => clearTimeout(timer);
  }, [isAutoplay, quizId, currentQuestionIndex, questions, autoplayTimer]);

  const toggleAutoplay = () => {
    setIsAutoplay(!isAutoplay);
  };

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    var getQuiz = async () => {
      const token = auth.user?.access_token;
      var response = await fetch(
        'api/quiz/' + quizId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      var result = await response.json();
      setQuiz(result);
    }

    getQuiz();
  }, [auth.isAuthenticated, auth.user?.access_token, quizId]);

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    if (!quiz) return;

    getQuizQuestions();
    const token = auth.user?.access_token;

    return connect(token, quiz.id);
  }, [quiz, auth.isAuthenticated]);

  function copyRoomCode() {
    var path = `${window.location.origin}/room/${roomCode}`;
    navigator.clipboard.writeText(path);
  }

  async function getQuizQuestions() {
    const token = auth.user?.access_token;
    var response = await fetch(
      `api/quiz/${quizId}/questions`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    var result = await response.json();
    setQuestions(result);
  }

  async function setQuestionAutoplayTimer(questionId, timer) {
    const token = auth.user?.access_token;

    var response = await fetch(
      `api/quiz/${quizId}/questions/${questionId}/autoplay?timer=${timer}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
    await response.text();

    await getQuizQuestions();
  }

  if (!quiz) {
    return (<p>Loading quiz...</p>);
  }

  return (
    <div>
      <div className="sticky-top bg-body">
        <div className="container-fluid">
          <header className="py-3 mb-4 border-bottom">
            <div className="container d-flex flex-wrap justify-content-center">
              <div className="me-auto">
                <h2>
                  {!quiz && "Loading..."}
                  {quiz && quiz.name}
                </h2>
                {quiz && <p className="text-muted">
                  You're now hosting your quiz. Copy the room code to the right, and share that with friends to join your quiz!
                </p>}
              </div>
              <div className="d-flex align-items-center">
                <ConnectionStatusBadge connectionStatus={connectionStatus} />

                {roomCode && <InputGroup className={"d-flex align-items-center w-auto ms-4"}>
                  <InputGroupText>{roomCode}</InputGroupText>
                  <Button onClick={copyRoomCode} outline={true}>
                    <FontAwesomeIcon className={"rounded-circle flex-shrink-0"} icon={faCopy} />
                  </Button>
                </InputGroup>}
              </div>

            </div>
          </header>
        </div>
      </div>

      <Row>
        <Col md={7}>
          {questions && questions.length === 0 && <p>No questions added</p>}
          {questions && questions.length > 0 &&
            <div>
              {questions.map((question, i) => (
                <Card className={`m-4 ${currentQuestionIndex == i && isAutoplay ? "border-success" : null}`} key={question.id}>
                  <CardHeader>
                    <h3>{question.question}</h3>

                  </CardHeader>

                  <CardBody>
                    {question.options.length === 0 && <p>No options added</p>}
                    {question.options.length > 0 &&
                      <Table>
                        <thead>
                          <tr>
                            <th>Option</th>
                          </tr>
                        </thead>
                        <tbody>
                          {question.options.map(option => {
                            return (
                              <tr key={option.id} className={option.isAnswer ? "table-success" : null}>
                                <td>
                                  <div className="d-flex align-items-start">
                                    {option.text.startsWith("img: ") && <img className="me-3" style={{ "height": "128px" }} src={option.text.substring("img: ".length)} />}
                                    {option.text}
                                    {option.isAnswer && <span className="ms-2 badge text-bg-secondary">Answer</span>}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    }
                  </CardBody>

                  <CardFooter>
                    <ButtonGroup>
                      <Button disabled={!!sending} color={"primary"} onClick={() => sendQuestion(quizId, question.id)}>No timer</Button>
                      <Button disabled={!!sending} color={"primary"} outline={true} onClick={() => sendQuestion(quizId, question.id, 30)}>Long (30s)</Button>
                      <Button disabled={!!sending} color={"primary"} outline={true} onClick={() => sendQuestion(quizId, question.id, 10)}>Quick (10s)</Button>
                    </ButtonGroup>

                    <ButtonGroup className="ms-4">
                      {(question.autoplayTimer ?? 10) > 1 && <Button color="primary" size="sm" outline={true} onClick={() => setQuestionAutoplayTimer(question.id, (question.autoplayTimer ?? 10) - 1)}>
                        <FontAwesomeIcon icon={faSubtract} />
                      </Button>}
                      <Button disabled={true}>{question.autoplayTimer ? `${question.autoplayTimer}s` : "10s"}</Button>
                      <Button color="primary" size="sm" outline={true} onClick={() => setQuestionAutoplayTimer(question.id, (question.autoplayTimer ?? 10) + 1)}>
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </ButtonGroup>

                  </CardFooter>
                </Card>
              ))}
            </div>
          }
        </Col>
        <Col md={5}>
          <Scoreboard scoreboard={scoreboard} />

          {sending && <div>
            <b>Question timer</b>
            <Timer seconds={sending} />
          </div>}

          <div className="mt-2">
            <h3>Autoplay</h3>

            <div className="">
              <ButtonGroup>
                {!isAutoplay && <Button color="primary" size="sm" onClick={toggleAutoplay}>
                  <FontAwesomeIcon icon={faPlay} />
                </Button>}
                {isAutoplay && <Button color="primary" outline={true} size="sm" onClick={toggleAutoplay}>
                  <FontAwesomeIcon icon={faPause} />
                </Button>}
                {currentQuestionIndex > 0 && <Button color="primary" outline={true} size="sm" onClick={() => setCurrentQuestionIndex(0)}><FontAwesomeIcon icon={faStop} /></Button>}
              </ButtonGroup>

              {isAutoplay && <Spinner size={"sm"} type={"grow"} className={"ms-2"} />}
            </div>

            {isAutoplay && questions && currentQuestionIndex < questions.length && <p><b>Current question:</b> {questions[currentQuestionIndex].question}</p>}
          </div>
        </Col>
      </Row>
    </div>
  );
}