import React, { useState } from 'react';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useAuth } from "react-oidc-context";

export function NavMenu() {
  const [collapsed, setCollapsed] = useState(false);
  const auth = useAuth();

  return (
    <header>
      <Navbar style={{"zIndex":1049}} className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
        <NavbarBrand tag={Link} to="/">Pubquiz</NavbarBrand>
        <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
            </NavItem>

            {auth.isAuthenticated && <UncontrolledDropdown nav>
              <DropdownToggle nav caret>
                {auth.isAuthenticated ? auth.user?.profile?.name ?? auth.user?.profile.sub : "Anonymous"}
              </DropdownToggle>
              <DropdownMenu>
                {auth.isAuthenticated && <DropdownItem onClick={() => auth.signoutRedirect()}>Logout</DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>}
            {!auth.isAuthenticated && 
            <NavItem>
              <NavLink
                href={"#"}
                onClick={() => auth.signinRedirect()}>Login</NavLink></NavItem>}
          </ul>

        </Collapse>
      </Navbar>
    </header>
  );
}
