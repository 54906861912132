import React, { createContext, useContext, useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

const HostHubSignalRContext = createContext();

export const HostHubSignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const [roomCode, setRoomCode] = useState(null);
  const [scoreboard, setScoreboard] = useState(null);
  const [sending, setSending] = useState(false);

  function sendQuestion(quizId, questionId, timer) {
    if (timer !== undefined){
      setSending(timer);

      setTimeout(() => {
        setSending(false);
      }, timer * 1000);
    }
    
    connection.invoke('SendQuestion', quizId, questionId, timer)
      .catch((err) => console.error('SendQuestion error:', err));
  }

  function connect(token, quizId) {
    setConnectionStatus("connecting");
    const newConnection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(`/hubs/host?quizId=${quizId}`, {
        accessTokenFactory: () => token
      })
      .build();

    newConnection.on('RoomCode', (code) => {
      setRoomCode(code);
    });

    newConnection.on('GetScoreboard', (scoreboard) => {
      setScoreboard(scoreboard);
    });

    setConnection(newConnection);

    newConnection.start()
      .then(() => {
        setConnectionStatus("connected");
      })
      .catch((err) => {
        setConnectionStatus("error")
        console.error('SignalR connection error:', err);
      });

    newConnection.onclose(() => setConnectionStatus("disconnected"));
    newConnection.onreconnecting(() => setConnectionStatus("reconnecting"));
    newConnection.onreconnected(() => setConnectionStatus("connected"));

    return () => {
      newConnection.stop().catch((err) => console.error('SignalR stop error:', err));
    };
  }

  return (
    <HostHubSignalRContext.Provider value={{ connect, connectionStatus, roomCode, sendQuestion, scoreboard, sending }}>
      {children}
    </HostHubSignalRContext.Provider>
  );
};

export const useHostHubSignalR = () => {
  return useContext(HostHubSignalRContext);
};