import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { QuizQuestion } from '../components/quizQuestion';

export function Quiz() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { quizId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [showAddQuestionSection, setShowAddQuestionSection] = useState(false);

  async function getQuiz() {
    const token = auth.user?.access_token;
    var response = await fetch(
      'api/quiz/' + quizId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    var result = await response.json();

    setQuiz(result);
  }

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    getQuiz();
  }, [auth.isAuthenticated]);

  async function getQuizQuestions() {
    const token = auth.user?.access_token;
    var response = await fetch(
      `api/quiz/${quizId}/questions`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    var result = await response.json();
    setQuestions(result);
  }

  useEffect(() => {
    if (!quiz) return;

    getQuizQuestions();
  }, [quiz]);

  async function createQuestion(event) {
    event.preventDefault();

    const token = auth.user?.access_token;

    var formData = new FormData(event.target);
    var data = {
      "question": formData.get("question")
    };
    event.target["question"].value = "";

    var response = await fetch(
      `api/quiz/${quizId}/questions`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': "application/json"
        },
        body: JSON.stringify(data)
      });
    await response.json();

    setShowAddQuestionSection(false);

    await getQuizQuestions();
  }

  return (
    <div>
      <div class="sticky-top bg-body">
        <div class="container-fluid">
          <header class="py-2 mb-4 border-bottom">
            <div class="container d-flex flex-wrap justify-content-center">
              <div className="me-auto">
                <Link to={"/quizzes"} className={"me-2"}>Go back...</Link>

                <h2>
                  {!quiz && "Loading..."}
                  {quiz && quiz.name}
                </h2>
                <p className="text-muted">
                  Set up your questions. Questions can have multiple answers!<br />

                  <b>Tip: </b>Create a question with no options for it to act like a round indicator.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <Button disabled={!questions || questions.length == 0} color="primary" onClick={() => navigate(`/host/${quiz.id}`)}>Host</Button>
              </div>

            </div>
          </header>
        </div>
      </div>

      {quiz && !questions && <p className={"ms-4"}>Loading questions...</p>}
      {questions &&
        <div>
          {questions.map((question, i) => (
            <QuizQuestion 
              key={question.id}
              reloadQuiz={getQuiz}
              quizId={quizId}
              title={question.question}
              question={question}
              showReorderUp={i > 0}
              showReorderDown={i < questions.length - 1}
              />
          ))}

          {showAddQuestionSection && <Card className={"m-4 border-primary"}>
            <CardHeader>
              <h4>New question</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={createQuestion}>
                <div className="mb-2">
                  <label className="form-label">Question</label>
                  <input type="text" className="form-control" name="question"
                    placeholder="What's the capital of France?" />
                </div>

                <button type="submit" class="btn btn-primary btn-sm me-2">
                  Add
                </button>
                <button className="btn btn-secondary btn-sm" onClick={() => setShowAddQuestionSection(false)}>
                  Cancel
                </button>
              </form>
            </CardBody>
          </Card>}
          {!showAddQuestionSection && <Button className={"ms-4 mb-4"} onClick={() => setShowAddQuestionSection(true)}>Add question</Button>}
        </div>
      }
    </div>
  );
}